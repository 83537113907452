<template>
  <div v-if="!isOwnerOrAgent" class="modal-card">
    <div class="box">
      <no-results
        :cta="{ label: 'Close' }"
        icon="user-shield"
        title="Invalid permissions"
        message="Orders can only be placed by site owners. Please ask the site owner to upgrade this subscription."
        @click="$emit('close')"
      />
    </div>
  </div>

  <modal-card v-else :title="step.label" :processing="processing">
    <div
      v-if="
        !$store.getters['auth/isCurrentUser'](userId) &&
          $store.getters['user/isAdmin']()
      "
      slot="subheader"
      class="has-background-warning has-padding-100 has-text-centered"
    >
      <p>
        <b-icon icon="shield-alt" size="is-small" />Tread carefully – you're
        acting on behalf of the customer.
      </p>
    </div>

    <loading v-if="isLoding" />

    <component
      :is="step.component"
      v-show="!isLoding"
      :contract-id="contract._id"
      :contract="contract"
      :user-id="userId"
      :cart="computedCart"
      :processing="processing"
      :is-upgrade="true"
      @cartdata="cart = $_.merge(cart, $event)"
    />

    <button
      slot="footer"
      :disabled="!step.isValid() || processing"
      :class="{ 'is-loading': processing }"
      type="submit"
      class="button is-success"
      @click="nextStep"
    >
      Continue
    </button>
  </modal-card>
</template>

<script>
import { doc, getDoc } from "@firebase/firestore";
export default {
  name: "UpgragePlanModal",
  props: {
    contract: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      isLoding: true,
      processing: false,
      cart: {
        credit: null,
        paymentMethod: null,
        product: null,
        productTerm: null,
        termsAgreed: false,
        contractId: this.contract._id,
        productId: null,
        productTermId: null
      },
      stepNum: 0,
      steps: [
        {
          label: "Select new plan",
          component: () =>
            import("@shared/cart/_selectUpgradePlan").finally(() => {
              this.isLoding = false;
            }),
          isValid: () => {
            return this.cart.productId && this.cart.productTermId;
          }
        },
        {
          label: "Select payment method",
          component: () =>
            import("@shared/cart/_selectPaymentMethod").finally(() => {
              this.isLoding = false;
            }),
          isValid: () => {
            return this.$_.isObject(this.cart.paymentMethod);
          }
        },
        {
          label: "Confirm upgrade",
          component: async () => {
            await this.getProduct();
            await this.getProductTerm();

            return import("@shared/cart/_confirmUpgrade").finally(() => {
              this.isLoding = false;
            });
          },
          isValid: () => {
            return this.cart.termsAgreed;
          }
        }
      ]
    };
  },
  computed: {
    userId() {
      return this.contract.authorId;
    },
    isOwnerOrAgent() {
      return (
        this.$store.getters["user/isAgent"]() ||
        this.contract.authorId === this.$store.state.auth.currentUser.uid
      );
    },
    step() {
      return this.steps[this.stepNum];
    },
    computedCart() {
      return this.$_.merge({}, this.cart, {
        credit: this.$store.getters["billing/credit"](this.userId)
      });
    }
  },
  created() {
    this.getCredit()
      .then(() => {
        this.isLoding = false;
      })
      .catch(error => {
        this.$toast.open({
          message: `${error.message}`,
          position: "is-bottom",
          queue: false,
          type: "is-danger"
        });
      });
  },
  beforeDestroy() {
    this.$store.dispatch("billing/unobserveCredit", {
      userId: this.userId
    });
  },
  methods: {
    getProduct() {
      const productRef = doc(
        this.$firestore,
        `products/${this.cart.productId}`
      );
      return getDoc(productRef).then(product => {
        this.$set(
          this.cart,
          "product",
          this.$_.merge({}, { _id: product.id }, product.data())
        );
      });
    },
    getProductTerm() {
      const productTermRef = doc(
        this.$firestore,
        `products/${this.cart.productId}/terms/${this.cart.productTermId}`
      );
      return getDoc(productTermRef).then(productTerm => {
        this.$set(
          this.cart,
          "productTerm",
          this.$_.merge({}, { _id: productTerm.id }, productTerm.data())
        );
      });
    },
    getCredit() {
      return this.$store.dispatch("billing/observeCredit", {
        userId: this.userId
      });
    },
    nextStep() {
      if (this.stepNum < this.steps.length - 1) {
        this.$_.debounce(() => {
          this.isLoding = true;
        }, 250);
        this.stepNum++;
      } else {
        this.doUpgrade();
      }
    },
    doUpgrade() {
      if (this.processing) return;
      this.processing = true;
      this.$store
        .dispatch("billing/upgradeContract", {
          contractId: this.cart.contractId,
          productId: this.cart.productId,
          productTermId: this.cart.productTermId,
          paymentMethodId: this.cart.paymentMethod.id
        })
        .then(result => {
          this.$emit("close");
          this.$emit("updated");
          this.$toast.open({
            message: `${result.message}`
          });
        })
        .catch(error => {
          this.$toast.open({
            message: `${error}`,
            type: "is-danger"
          });
        })
        .finally(() => {
          this.processing = false;
        });
    }
  }
};
</script>
